<template>
  <div id="escuela-admin">
    <v-container grid-list-xl fluid>
      <header-title title="Escuela"></header-title>
      <v-card-title>
        <span class="grey--text text--darken-1">{{ text_mode }}</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark to="/institucion">
          <v-icon>mdi-arrow-left</v-icon>Volver
        </v-btn>
      </v-card-title>
      <v-form @submit="submitInstitucion" ref="form">
        <v-row>
          <v-col cols md="4">
            <v-text-field
              type="text"
              label="Código Escuela *"
              v-model.trim="institucion.codigo"
              :rules="[
                (v) => !!v || 'El campo es requerido',
                (v) => v.length <= 3 || 'Longitud máxima 3',
              ]"
              :counter="3"
              autocomplete="off"
              required
            ></v-text-field>
            <v-select
              label="Tipo Escuela *"
              :items="tipo_instituciones"
              v-model="institucion.tipo_institucion"
              :rules="[(v) => !!v || 'El campo es requerido']"
              required
            ></v-select>
            <v-text-field
              type="text"
              label="Nombre *"
              v-model.trim="institucion.nombre"
              :rules="[(v) => !!v || 'El campo es requerido']"
              autocomplete="off"
              required
            ></v-text-field>
            <v-text-field
              type="text"
              label="Descripción *"
              v-model.trim="institucion.descripcion"
              :rules="[(v) => !!v || 'El campo es requerido']"
              autocomplete="off"
              required
            ></v-text-field>
            <v-text-field
              type="text"
              label="Código Univ."
              v-model.trim="institucion.codigo_univ"
              autocomplete="off"
              required
            ></v-text-field>
            <v-text-field
              type="text"
              label="Código Normal"
              v-model.trim="institucion.codigo_normal"
              autocomplete="off"
              required
            ></v-text-field>
            <v-text-field
              type="text"
              label="Código UE"
              v-model.trim="institucion.codigo_ue"
              autocomplete="off"
              required
            ></v-text-field>
            <v-text-field
              type="text"
              label="Dirección *"
              v-model.trim="institucion.direccion"
              :rules="[(v) => !!v || 'El campo es requerido']"
              autocomplete="off"
              required
            ></v-text-field>
          </v-col>
          <v-col cols md="4">
            <v-text-field
              type="text"
              label="Correo"
              v-model.trim="institucion.correo"
              autocomplete="off"
              required
            ></v-text-field>
            <v-text-field
              type="text"
              label="Teléfono"
              v-model.trim="institucion.telefono"
              autocomplete="off"
              required
            ></v-text-field>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Fecha creación *"
                  append-icon="mdi-calendar"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="institucion.fecha_creacion"
                @input="menu = false"
                locale="es"
              ></v-date-picker>
            </v-menu>
            <v-autocomplete
              label="Departamento *"
              :items="departamentos"
              v-model="institucion.departamento_id"
              item-text="descripcion"
              item-value="id"
              :rules="[(v) => !!v || 'El campo es requerido']"
              @change="getProvincias"
              required
            ></v-autocomplete>
            <v-autocomplete
              label="Provincia *"
              :items="provincias"
              v-model="institucion.provincia_id"
              item-text="descripcion"
              item-value="id"
              :rules="[(v) => !!v || 'El campo es requerido']"
              @change="getMunicipios"
              required
            ></v-autocomplete>
            <v-autocomplete
              label="Municipio *"
              :items="municipios"
              v-model="institucion.municipio_id"
              item-text="descripcion"
              item-value="id"
              :rules="[(v) => !!v || 'El campo es requerido']"
              @change="getCantones"
              required
            ></v-autocomplete>
            <v-autocomplete
              label="Cantón *"
              :items="cantones"
              v-model="institucion.canton_id"
              item-text="descripcion"
              item-value="id"
              :rules="[(v) => !!v || 'El campo es requerido']"
              @change="getLocalidades"
              required
            ></v-autocomplete>
            <v-autocomplete
              label="Localidad *"
              :items="localidades"
              v-model="institucion.localidad_id"
              item-text="descripcion"
              item-value="id"
              :rules="[(v) => !!v || 'El campo es requerido']"
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols sm="12" md="4">
            <v-file-input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              label="Portada *"
              @change="onFileChange($event)"
              :rules="[(v) => !!v || 'El documento es requerido']"
              required
            ></v-file-input>
            <v-text-field
              type="text"
              label="Localización *"
              v-model.trim="institucion.coordenada"
              :rules="[(v) => !!v || 'El campo es requerido']"
              autocomplete="off"
              required
              readonly
              @click="showDialogMap"
            ></v-text-field>
            <span class="grey--text text--darken-2">
              Marque la ubicación de la ESFM.
            </span>
            <div id="geomap" style="width: 100% !important; height: 40vh"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn type="submit" :loading="btn_loading" color="success" dark>
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog
      v-model="dialogMap"
      persistent
      :width="size_width > 1024 ? '51%' : '90%'"
    >
      <v-card>
        <v-card-title class="headline grey lighten-3">
          <span class="headline">Geolocalización</span>
        </v-card-title>
        <v-card-text class="text-xs-center">
          <p class="grey--text text--darken-2">
            Marque la ubicación de la ESFM.
          </p>
          <!-- <div id="geomap" style="width: 100% !important; height: 50vh"></div> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogMap = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
    >
      {{ snack.text }}
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
  name: "escuela-admin",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      menu: false,
      btn_loading: false,
      size_width: 0,
      text_mode: "Agregar",
      departamentos: [],
      provincias: [],
      municipios: [],
      cantones: [],
      localidades: [],
      tipo_instituciones: [],
      institucion: {
        id: "",
        codigo: "",
        tipo_institucion: "",
        nombre: "",
        descripcion: "",
        codigo_univ: "0",
        codigo_normal: "0",
        codigo_ue: "0",
        departamento_id: "",
        provincia_id: "",
        municipio_id: "",
        canton_id: "",
        localidad_id: "",
        direccion: "",
        correo: "",
        telefono: "",
        fecha_creacion: "",
        coordenada: "",
        portada: "",
        estado: true,
      },
      dialogMap: false,
      map: null,
      lmarker: null,
      snack: {
        state: false,
        color: "success",
        text: "",
      },
    };
  },
  created() {
    // this.serve = Service.getServe();
    mapboxgl.accessToken = Service.getTokenMap();
  },
  computed: {
    dateFormatted() {
      if (!this.institucion.fecha_creacion) return null;

      const [year, month, day] = this.institucion.fecha_creacion.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    if (Service.getUser()) {
      if (Service.getUser().role == 1) {
        this.tipo_instituciones = ["ESFM", "UA"];
        this.getDepartamentos();
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
    let self = this;
    this.lmarker;
    let cmarker;
    this.map = new mapboxgl.Map({
      container: "geomap",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-63.588654, -16.290154],
      zoom: 5,
    });
    this.map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        countries: "bo",
        placeholder: "Buscar lugar",
        zoom: 14,
      })
    );
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.doubleClickZoom.disable();
    this.map.on("click", (ev) => {
      if (this.lmarker) this.lmarker.remove();
      cmarker = new mapboxgl.Marker()
        .setLngLat([ev.lngLat.lng, ev.lngLat.lat])
        .addTo(this.map);
      self.institucion.coordenada = JSON.stringify([
        ev.lngLat.lng,
        ev.lngLat.lat,
      ]);
      this.lmarker = cmarker;
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWidth);
      this.getWidth();
    });
  },
  methods: {
    getWidth() {
      this.size_width = document.documentElement.clientWidth;
    },
    onFileChange(event) {
      this.institucion.portada = event;
    },
    getDepartamentos() {
      this.provincias = [];
      this.municipios = [];
      this.cantones = [];
      this.localidades = [];
      axios
        .get(Service.getBase() + "departamentos/activo", Service.getHeader())
        .then((response) => {
          this.departamentos = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    getProvincias() {
      this.municipios = [];
      this.cantones = [];
      this.localidades = [];
      axios
        .get(
          Service.getBase() +
            "provincias/activo/" +
            this.institucion.departamento_id,
          Service.getHeader()
        )
        .then((response) => {
          this.provincias = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    getMunicipios() {
      this.cantones = [];
      this.localidades = [];
      axios
        .get(
          Service.getBase() +
            "municipios/activo/" +
            this.institucion.provincia_id,
          Service.getHeader()
        )
        .then((response) => {
          this.municipios = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    getCantones() {
      this.localidades = [];
      axios
        .get(
          Service.getBase() +
            "cantones/activo/" +
            this.institucion.municipio_id,
          Service.getHeader()
        )
        .then((response) => {
          this.cantones = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    getLocalidades() {
      axios
        .get(
          Service.getBase() +
            "localidades/activo/" +
            this.institucion.canton_id,
          Service.getHeader()
        )
        .then((response) => {
          this.localidades = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    showDialogMap() {
      this.dialogMap = true;
    },
    submitInstitucion() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        let fdata = new FormData();
        fdata.append("codigo", this.institucion.codigo);
        fdata.append("tipo_institucion", this.institucion.tipo_institucion);
        fdata.append("nombre", this.institucion.nombre);
        fdata.append("descripcion", this.institucion.descripcion);
        fdata.append("codigo_univ", this.institucion.codigo_univ);
        fdata.append("codigo_normal", this.institucion.codigo_normal);
        fdata.append("codigo_ue", this.institucion.codigo_ue);
        fdata.append("departamento_id", this.institucion.departamento_id);
        fdata.append("provincia_id", this.institucion.provincia_id);
        fdata.append("municipio_id", this.institucion.municipio_id);
        fdata.append("canton_id", this.institucion.canton_id);
        fdata.append("localidad_id", this.institucion.localidad_id);
        fdata.append("direccion", this.institucion.direccion);
        fdata.append("coordenada", this.institucion.coordenada);
        fdata.append("portada", this.institucion.portada);
        fdata.append("correo", this.institucion.correo);
        fdata.append("telefono", this.institucion.telefono);
        fdata.append("fecha_creacion", this.institucion.fecha_creacion);
        fdata.append("estado", this.institucion.estado);
        axios
          .post(Service.getBase() + "institucion", fdata, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: Service.getToken(),
            },
          })
          .then((response) => {
            this.btn_loading = false;
            if (response.status === 201) {
              if (this.$refs.form) {
                this.$refs.form.resetValidation();
                this.$refs.form.reset();
              }
              if (this.lmarker) {
                this.lmarker.remove();
                this.lmarker = null;
              }
              this.toast("success", response.data.message);
            } else {
              this.toast("warning", "No se pudo registrar");
            }
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast("error", "No se pudo guardar");
          });
      }
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
